import { container } from 'tsyringe'
import Vue from 'vue'
import store from '@/app/ui/store'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import { SelectedMerchant } from '@/domain/entities/SelectedMerchant'
import { SelectedMerchantPresenter } from '@/app/ui/presenters/SelectedMerchantPresenter'
import { SelectedMerchantRequest } from '@/data/payload/api/SelectedMerchantRequest'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import { MerchantDropdown } from '@/domain/entities/Merchant'

export interface SelectedMerchantState {
  isLoading: boolean
  listSelectedMerchant: SelectedMerchant[]
  cacheListSelectedMerchant: SelectedMerchant[]
}

export interface DropDownInterface {
  value: number | string
  label: string
}

@Module({ namespaced: true, store, name: 'selected-merchant', dynamic: true })
class SelectedMerchantController extends VuexModule
  implements SelectedMerchantState {
  private presenter: SelectedMerchantPresenter = container.resolve(
    SelectedMerchantPresenter
  )

  isLoading = false
  listSelectedMerchant: SelectedMerchant[] = []
  cacheListSelectedMerchant: SelectedMerchant[] = []
  successSave = false
  merchantDropdown: DropDownInterface[] = []

  // getter

  @Action({ rawError: true })
  getAllSelectedMerchant() {
    this.setLoading(true)

    this.presenter
      .getAll()
      .then(res => {
        this.setCacheListSelectedMerchant(res)
        this.setListSelectedMerchant(res)
      })
      .catch(err => {
        Vue.notify({
          title: 'Fetch List Selected Merchant Failed',
          text:
            err.status === 400 || err.status === 422
              ? err.error.message.en
              : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true })
  saveSelectedMerchants(merchantIDs: number[]) {
    this.setLoading(true)
    this.setSuccessSave(false)
    this.presenter
      .create(new SelectedMerchantRequest(merchantIDs))
      .then(() => {
        this.setSuccessSave(true)
        EventBus.$emit(EventBusConstants.SAVE_SELECTED_MERCHANT_SUCCESS, {})
      })
      .catch(err => {
        this.setSuccessSave(false)
        Vue.notify({
          title: 'Save List Selected Merchant Failed',
          text:
            err.status === 400 || err.status === 422
              ? err.error.message.en
              : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setSuccessSave(true)
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  getAllDropdown() {
    this.setLoading(true)
    this.presenter
      .getAllDropdown()
      .then(res => this.setMerchantDropdown(res))
      .catch(error => {
        Vue.notify({
          title: 'Fetch Merchants Dropdown Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => this.setLoading(false))
  }

  // end getter

  // setter

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  setListSelectedMerchant(data: SelectedMerchant[]) {
    this.listSelectedMerchant = data
  }

  @Mutation
  private setSuccessSave(bool: boolean) {
    this.successSave = bool
  }

  @Mutation
  private setCacheListSelectedMerchant(data: SelectedMerchant[]) {
    this.cacheListSelectedMerchant = data
  }

  @Mutation
  private setMerchantDropdown(data: MerchantDropdown[]) {
    this.merchantDropdown = data.map(merchant => ({
      value: merchant.merchantID,
      label: merchant.merchantName,
    })) as DropDownInterface[]
  }

  // end setter
}

export default getModule(SelectedMerchantController)
