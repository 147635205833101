import { SaveSelectedMerchantRequestInterface } from '@/data/payload/contracts/SelectedMerchantRequest'

export class SelectedMerchantRequest
  implements SaveSelectedMerchantRequestInterface {
  merchantIDs!: number[]

  constructor(merchantIDs: number[]) {
    this.merchantIDs = merchantIDs
  }

  public toPayload(): string {
    return JSON.stringify({
      list_merchant_ids: this.merchantIDs,
    })
  }
}
