





























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import EmptyState from '@/app/ui/assets/EmptyState/no_data-paket.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import controller, { DropDownInterface } from '@/app/ui/controllers/SelectedMerchantController'
import { SelectedMerchant } from '@/domain/entities/SelectedMerchant'
import ModalSuccess from './components/ModalSuccess/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

@Component({
  name: 'SelectedMerchant',
  components: {
    Button,
    DataTable,
    DropdownSelect,
    EmptyState,
    ModalSuccess,
    LoadingOverlay,
    MultiselectV2
  },
})
export default class SelectedMerchantPage extends Vue {
  controller = controller
  confirmationModal = false
  cacheSelectedMerchants: DropDownInterface[] = []
  listMerchantOptions: DropDownInterface[] = []
  chooseMerchant = false
  savingProcess = false
  eventChoose = 0
  countEventInput = 0

  created(): void {
    controller.getAllSelectedMerchant()
    controller.getAllDropdown()
  }

  public handleChooseMerchant(): void {
    this.chooseMerchant = true;
    this.eventChoose+=1
  }

  private merchantSearch(keyword: string): void {
    if (keyword.length >= 3) {
      const result = controller.merchantDropdown
        .map(merchant => {
          if (merchant.label.toLowerCase().includes(keyword.toLowerCase())) {
            return merchant
          } else {
            return null
          }
        }).filter(item => item)

      this.listMerchantOptions = <DropDownInterface[]>result
    } else {
      this.listMerchantOptions = controller.merchantDropdown
    }
  }

  public cancel(): void {
    this.countEventInput = 0
    if (!this.isLoading) {
      this.listSelectedMerchant = this.listCacheSelectedMerchant
    }
    this.eventChoose = 0
    this.chooseMerchant = false
  }

  public choose(): void {
    this.eventChoose++
    if (this.eventChoose === 1 && this.chooseMerchant) {
      this.listSelectedMerchant = []
    }

    const chooseDataNullAndListDBEmpty = this.listSelectedMerchant.length === 0 && this.listCacheSelectedMerchant.length === 0
    if (chooseDataNullAndListDBEmpty) {
      this.cancel()
    }

    if (this.eventChoose > 1 && this.chooseMerchant && !chooseDataNullAndListDBEmpty) {
      const listIDs = <number[]>this.listSelectedMerchant.map(merchant => merchant.value)
      const cache = <number[]>this.listCacheSelectedMerchant.map(merchant => merchant.value)
      const req = <number[]>[...new Set([...listIDs, ...cache])]
      const newReq: number[] = []
      for (const number of listIDs) {
        if(req.includes(number)) {
          newReq.push(number)
        }
      }
      if (this.countEventInput === 0) {
        newReq.push(...req)
      }
      controller.saveSelectedMerchants(newReq)
      this.savingProcess = true
      this.chooseMerchant = newReq.length !== 0
    }
  }

  public eventInput(): void {
    if (this.countEventInput === 0) {
      this.listSelectedMerchant = <DropDownInterface[]>controller.cacheListSelectedMerchant.map(merchant => ({
        value: merchant.merchantID,
        label: merchant.merchantName,
      }))
    }
    this.countEventInput++
  }

  public eventInputClose(): void {
    this.eventChoose++
  }

  public updateAfterSuccess(): void {
    this.successModal = false
    this.savingProcess = false
  }

  @Watch('controller.merchantDropdown')
  private getController(value: DropDownInterface[]): void {
    this.listMerchantOptions = value
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get dataSelectedMerchants(): Array<Array<string>> {
    if (this.listSelectedMerchant.length >= 1 || this.countEventInput >= 1) {
      return this.listSelectedMerchant.map(merchant => [merchant.label])
    }
    return this.listCacheSelectedMerchant.map(merchant => [merchant.label])
  }

  get listCacheSelectedMerchant(): DropDownInterface[] {
    return <DropDownInterface[]>controller.cacheListSelectedMerchant.map(merchant => ({
      value: merchant.merchantID,
      label: merchant.merchantName,
    }))
  }

  get successModal(): boolean {
    return !this.isLoading && this.savingProcess && controller.successSave
  }

  set successModal(bool: boolean) {
    this.cancel()
    controller.getAllSelectedMerchant()
  }

  get listSelectedMerchant(): DropDownInterface[] {
    const data = <DropDownInterface[]>controller.listSelectedMerchant.map(merchant => ({
      value: merchant.merchantID,
      label: merchant.merchantName,
    }))
    if (data.length !== 0) {
      this.chooseMerchant = true
    }
    return data
  }

  set listSelectedMerchant(data: DropDownInterface[]) {
    const newData = data.map(merchant => new SelectedMerchant(undefined, merchant.label, <number>merchant.value))
    controller.setListSelectedMerchant(newData)
  }
}
