




















import { Prop, Component, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import SuccessIcon from '@/app/ui/assets/success_icon_alt.vue'

@Component({
  components: {
    Modal,
    Button,
    SuccessIcon,
  },
})
export default class CategoryItem extends Vue {
  @Prop({ default: true }) private value!: boolean
  @Prop({ default: true }) private visible!: boolean
  @Prop({ default: 'Saved' }) private title!: string
  @Prop({ default: 'Changes has been saved' }) private message!: string

  private hideModal() {
    this.$emit('change')
  }
}
